.apexcharts-legend-series {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
}

.apexcharts-legend-text {
  font-family: Open Sans, sans-serif !important;
  color: #5c5c5c !important;
  font-size: 14px !important;
}

.apexcharts-xaxis-label {
  font-weight: 400;
}

.apexcharts-xaxis-title {
  font-style: italic;
  color: #5c5c5c;
}

.apexcharts-legend-marker {
  border-radius: 6px !important;
  margin: 0px 8px;
}

.apexcharts-legend.position-bottom.apexcharts-align-center,
.apexcharts-legend.position-top.apexcharts-align-center {
  right: auto !important;
}
